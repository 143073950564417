import { graphql, PageProps } from "gatsby"
import Page from "@components/Nutritionist/NutritionistList"

export type Props = PageProps<GatsbyTypes.PageNutritionistsQuery, GatsbyTypes.PageNutritionistsQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageNutritionists {
    page: sanityPageNutritionists {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      sections: _rawSections(resolveReferences: { maxDepth: 8 })
      additionalSearchBarPlaceholder
      additionalNoNutritionistsLabel
      _type
    }
    nutritionists: allSanityNutritionist(limit: 24, sort: { fields: title }) {
      edges {
        node {
          ...SanityNutritionistFragment
        }
      }
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
