import { useEffect } from "react"
import { SORT_OPTION_VALUES } from "@app/hooks/useListingFilters"
import { useSanity } from "./useSanity"
import { useListingFilteringLogic } from "./useListingFilteringLogic"

// type useListingNutritionistsArgs = {
//   nutritionists: any
// }

const sortOptions = [
  { label: "A-Z", value: SORT_OPTION_VALUES.A_TO_Z },
  { label: "Z-A", value: SORT_OPTION_VALUES.Z_TO_A },
  // { label: "Location", value: SORT_OPTION_VALUES.LOCATION },
  // TODO: re-activate below once review system is setup
  // { label: "Highest Reviewed", value: "highest-reviewed" },
]

const ITEMS_PER_PAGE = 24

const projection = [
  `{
    _id,
    handle {
      current
    },
    image {
      alt,
      asset -> {
        url
      }
    },
    url,
    title,
    summary,
  }`,
]

const useListingNutritionists = () => {
  const { getSanity, sanityItems, loading } = useSanity()

  const { searchTerm, handleSearchChange, activeSortOption, handleSortChange, handleSearchSubmit, paginationOptions, submittedSearchTerm } =
    useListingFilteringLogic({
      itemsPerPage: ITEMS_PER_PAGE,
      sortOptions,
      initialItems: sanityItems?.paginated,
      totalItems: sanityItems?.all,
    })

  useEffect(() => {
    const searchTerm = submittedSearchTerm ? `&& [title, description] match "*${submittedSearchTerm}*"` : ""

    const baseQuery = [`*[_type == "nutritionist" && !(_id in path('drafts.**')) ${searchTerm}]`]

    const pagination = `[
      ${(paginationOptions?.humanCurrentPageNumber - 1) * ITEMS_PER_PAGE}...${paginationOptions?.humanCurrentPageNumber * ITEMS_PER_PAGE}
    ]`

    const sorts = [`| order(${activeSortOption?.value})`]

    const paginatedQuery = baseQuery.concat(sorts).concat(pagination).concat(projection).join("")

    const allQuery = `count(${baseQuery})`

    const query = `{ 'paginated': ${paginatedQuery}, 'all': ${allQuery} }`

    getSanity(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedSearchTerm, activeSortOption, paginationOptions?.humanCurrentPageNumber])

  return {
    filteredItems: sanityItems?.paginated,
    hasItems: !!sanityItems?.paginated?.length,
    searchTerm,
    handleSearchChange,
    sortOptions,
    activeSortOption,
    handleSortChange,
    handleSearchSubmit,
    paginationOptions,
    loading,
    itemsPerPage: ITEMS_PER_PAGE,
  }
}

export { useListingNutritionists }
