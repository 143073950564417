import { Box } from "@chakra-ui/react"
import { Listing } from "@components/Listing/Listing"
import { SearchBar } from "@components/Search/SearchBar"
import { NutritionistItem } from "./NutritionistItem"
import { useListingNutritionists } from "@app/hooks/useListingNutritionists"
import { memo, useMemo } from "react"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/pages/nutritionists"
import type { PageProps } from "@root/types/global"

const NutritionistListHeaderChildren: React.FC = ({ searchTerm, handleSearchChange, handleSearchSubmit, page }: any) => (
  <Box mt={[3, 3, 6]}>
    <SearchBar
      placeholder={page?.additionalSearchBarPlaceholder}
      bgColor="brand.offWhite"
      value={searchTerm}
      handleChange={handleSearchChange}
      handleSubmit={handleSearchSubmit}
    />
  </Box>
)

const NutritionistListGridChildren = ({ filteredItems }: any) =>
  filteredItems?.map((nutritionist: any) => <NutritionistItem key={nutritionist?._id} nutritionist={nutritionist} textAlignMobile="left" />)

const NutritionistListing: React.FC<PageProps<Props>> = ({ page, nutritionists }) => {
  const {
    hasItems,
    searchTerm,
    handleSearchChange,
    filteredItems,
    sortOptions,
    activeSortOption,
    handleSortChange,
    handleSearchSubmit,
    paginationOptions,
    loading,
    itemsPerPage,
  } = useListingNutritionists({
    nutritionists,
  })

  const headerChildren = useMemo(
    () => (
      <NutritionistListHeaderChildren
        handleSearchChange={handleSearchChange}
        page={page}
        searchTerm={searchTerm}
        handleSearchSubmit={handleSearchSubmit}
      />
    ),
    [handleSearchChange, handleSearchSubmit, page, searchTerm]
  )

  return (
    <Listing
      headerProps={{
        title: page?.title,
        children: headerChildren,
        paddingTop: [12, 14, 18],
        paddingBottom: [8, 8, 11],
      }}
      gridProps={{
        hasItems,
        columns: [1, 2, 3, 4],
        children: <NutritionistListGridChildren filteredItems={filteredItems} />,
        columnGap: [0, 4.5],
        rowGap: [10, 10, 12],
        noItemsLabel: page?.additionalNoNutritionistsLabel,
        itemsPerPage,
      }}
      paginationProps={{
        computedOptions: paginationOptions,
      }}
      sortOptions={sortOptions}
      handleSortChange={handleSortChange}
      activeSortOption={activeSortOption}
      showBottomBorder
      showFilters={false}
      loading={loading}
    />
  )
}

const NutritionistList: React.FC<PageProps<Props>> = ({ page, nutritionists }) => {
  return (
    <>
      <NutritionistListing page={page} nutritionists={nutritionists} />
      {!!page?.sections?.length && <Sections page={{ content: page?.sections }} />}
    </>
  )
}

export default memo(NutritionistList)
