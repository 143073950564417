import { memo } from "react"
import { Heading, Text, Box } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { CustomImage } from "@components/Image"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import { useSanityImageUrl } from "@app/hooks/useSanity"

type NutritionistItemItemProps = {
  nutritionist: any
  handleTrackingClick?: () => void
  textAlignMobile?: string
}

const NutritionistItem: React.FC<NutritionistItemItemProps> = ({ nutritionist, handleTrackingClick, textAlignMobile = "center" }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const resolvedUrl = nutritionist?.link || urlResolver(nutritionist)
  const { getSanityImageUrl } = useSanityImageUrl()

  const sanityImgUrl = nutritionist?.image?.src || getSanityImageUrl(nutritionist?.image?.asset?.url, 320, 240) || ""

  return (
    <Box as="article" overflow="hidden" w="full" display="flex" flexDirection="column" h="full">
      {nutritionist?.image && (
        <CustomImage
          src={sanityImgUrl}
          srcSet={nutritionist?.image?.srcSet}
          sizes={nutritionist?.image?.sizes}
          alt={nutritionist?.image?.alt || nutritionist?.title}
          width="full"
          aspectRatio="article"
        />
      )}
      <Box textAlign={[textAlignMobile, "left"]} h="full" display="flex" flexDir="column" justifyContent="space-between">
        {nutritionist?.title && (
          <Heading as="p" size="h4" mb={2} mt={4}>
            {nutritionist?.title}
          </Heading>
        )}
        {nutritionist?.summary && (
          <Text lineHeight="24px" fontSize="md" flexGrow={1}>
            {nutritionist?.summary}
          </Text>
        )}
        <CustomLink
          to={resolvedUrl?.url ? `${routes.NUTRITIONIST}${resolvedUrl?.url}` : nutritionist?.link}
          title={nutritionist?.title}
          onClick={handleTrackingClick}
          styles={{
            mt: [3, 4],
          }}
          variant="underline"
        >
          <Text as="span" fontSize="sm">
            {/* TODO: move this to additionals */}
            View Profile
          </Text>
        </CustomLink>
      </Box>
    </Box>
  )
}

const MemoNutritionistItem = memo(NutritionistItem)
export { MemoNutritionistItem as NutritionistItem }
